import { ethers } from "ethers";
import { SIGNING_DOMAIN_NAME } from "../services/contants";
import { SIGNING_DOMAIN_VERSION } from "../services/contants";

// These constants must match the ones used in the smart contract.

class LazyMinter {
  /**
   * Create a new LazyMinter targeting a deployed instance of the LazyNFT contract.
   *
   * @param {Object} options
   * @param {ethers.Contract} contract an ethers Contract that's wired up to the deployed contract
   * @param {ethers.Signer} signer a Signer whose account is authorized to mint NFTs on the deployed contract
   */
  constructor({ contract, signer }) {
    this.contract = contract;
    this.signer = signer;
  }

  /**
   * Creates a new NFTVoucher object and signs it using this LazyMinter's signing key.
   *
   * @param {ethers.BigNumber | number} tokenId the id of the un-minted NFT
   * @param {string} uri the metadata URI to associate with this NFT
   * @param {ethers.BigNumber | number} minPrice the minimum price (in wei) that the creator will accept to redeem this NFT. defaults to zero
   *
   * @returns {NFTVoucher}
   */
  // used to create Voucher
  async createVoucher(tokenId, uri, time, minPrice) {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    // MetaMask requires requesting permission to connect users accounts
    return new Promise(async (resolve, reject) => {
      try {
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();
        // const voucher = { tokenId, uri, time, minPrice };
        // const domain = await this._signingDomain();
        const  RandomNumber = Math.floor(Math.random() * 1000) + 1 ;
        const nonce = RandomNumber;
        const voucher = { nonce, minPrice, uri }
        const domain = await this._signingDomain()
        const types = {
          NFTVoucher: [
            {name: "nonce", type: "uint256"},
            {name: "minPrice", type: "uint256"},         
            {name: "uri", type: "string"}            
          ],
        };
        const signature = await signer._signTypedData(domain, types, voucher)
        let voucherResult = {
          ...voucher,
          signature,
        };
        resolve(voucherResult);
      } catch (e) {
        reject(e);
      }
    });
  }

  async _formatVoucher(voucher) {
    let types = {
      EIP712Domain: [
        { name: "name", type: "string" },
        { name: "version", type: "string" },
        { name: "chainId", type: "uint256" },
        { name: "verifyingContract", type: "address" },
      ],
      NFTVoucher: [
        { name: "tokenId", type: "uint256" },
        { name: "minPrice", type: "uint256" },
        { name: "uri", type: "string" },
      ],
    };

    const domain = await this._signingDomain();
    return {
      domain,
      types: types,
      primaryType: "NFTVoucher",
      message: voucher,
    };
  }
  /**
   * @private
   * @returns {object} the EIP-721 signing domain, tied to the chainId of the signer
   */
  async _signingDomain() {
    if (this._domain != null) {
      return this._domain;
    }
    this._domain = {
      name: SIGNING_DOMAIN_NAME,
      version: SIGNING_DOMAIN_VERSION,
      verifyingContract: "0xE8568D5B2c7e1E91C3F98CbaB37DA876EA7e5a4a",
      chainId: 5,
    };
    return this._domain;
  }
}

export default LazyMinter;
