// import { getContract } from "./contract";
import { Web3Provider } from "@ethersproject/providers";
// import React from "react";
import WalletLink from "walletlink";

// import { ethers } from "ethers";
// import WalletConnectProvider from "@walletconnect/web3-provider";
// import * as config from "./config";
// import { IWallet, defaultWallet } from "./interfaces";
// import * as utils from "./utils";

//connect metamask
export const connectMetamask = async () => {
  return new Promise((resolve, reject) => {
    const { ethereum } = window;
    if (ethereum.isCoinbaseWallet === true) {
      alert("Please install Metamask!");
      if (
        window.confirm(
          "You will be redirected to MetaMask now. Do you wish to proceed?"
        )
      ) {

        window.open(`https://metamask.io/download.html`, "_blank");
      } else {
        // setLoading(false);
      }

    } else {
      if (!ethereum) {
        alert("Please install Metamask!");
        if (
          window.confirm(
            "You will be redirected to MetaMask now. Do you wish to proceed?"
          )
        ) {

          window.open(`https://metamask.io/download.html`, "_blank");
        } else {
          // setLoading(false);
        }
      } else {
        ethereum
          .request({
            method: "eth_requestAccounts",
          })
          .then((accounts) => {
            const account = accounts[0];
            const library = new Web3Provider(ethereum, "any");
            // setWeb3Library(library);
            resolve({ account, library });
          })
          .catch((err) => {

            reject(err);
          });
      }
    }

  });
};

//connect coinbase
export const connectCoinbase = async () => {
  // Initialize WalletLink
  return new Promise((resolve, reject) => {
    const walletLink = new WalletLink({
      appName: "Paiverse",
      darkMode: true,
    });
    // const { ethereum } = window;
    const provider = walletLink.makeWeb3Provider(
      "https://mainnet.infura.io/v3/55d040fb60064deaa7acc8e320d99bd4", 1
    );

    provider
      .request({
        method: "eth_requestAccounts",
      })
      .then((accounts) => {
        const account = accounts[0];
        const library = new Web3Provider(provider, "any");
        resolve({ account, library });
      })
      .catch((err) => {
        reject(err);
      });
  });
};


export const connectTrustWellat = async (IWallet) => {
}

